import { ActionType as ActionTypeBackend } from "../backend/types";
import { ActionType } from "./stringHelper";

export function checkIfActionIsNotEditable(
  currentAction: ActionTypeBackend
): boolean {
  return [
    ActionType.DOWNLOAD,
    ActionType.ARCHIVE,
    ActionType.UNCOMPRESS,
    ActionType.RENAME,
    ActionType.RESTORE,
    ActionType.COPY,
    ActionType.MOVE
  ].includes(ActionType[currentAction]);
}
