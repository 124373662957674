import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import Folder from "@mui/icons-material/Folder";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import {
  TreeItem,
  treeItemClasses,
  TreeItemProps
} from "@mui/x-tree-view/TreeItem";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  formControlLabelClasses
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { alpha, styled } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  GetRepoObjectQueryData,
  ValidityStatus,
  ValidityStatusType
} from "../../backend/types";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import {
  BpCheckbox,
  RepoObject,
  getFlattenedTree
} from "../../helpers/treeHelper";
import FileIcon from "./fileIcon";
import { SVGIcons } from "./svgIcons/svgIcons";

interface FileTreeViewProps {
  filesSelection: any;
  updateFileSelection: (fileType: FileType, node: any) => void;
  fileType: FileType;
  isFileHidden?: (file: RepoObject) => boolean;
  treeSelection?: any;
  parentLoading?: boolean[];
}

export const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 9,
    paddingLeft: 5,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  },
  [`& .${treeItemClasses.content}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2
  },
  [`& .${formControlLabelClasses.root}`]: {
    margin: 0
  }
}));

export enum FileType {
  Input = "input",
  Script = "entryPoint",
  WorkingDir = "workingDir",
  CompareView = "compareView"
}

export function FileTreeView(props: FileTreeViewProps) {
  const { filesSelection, updateFileSelection, fileType, isFileHidden } = props;
  const { refetchRepoObjects, isLoadingGetRepoObjects, treeHierarchyGlobal } =
    useContext(BrowsePmxActivityContext);
  const [expanded, setExpanded] = useState<[string]>(["/"]);
  const [selected, setSelected] = useState<any>([]);
  const [flattenTree, setFlattenTree] = useState<any[]>([]);
  const treeData =
    props.treeSelection && props.treeSelection.length > 0
      ? props.treeSelection
      : treeHierarchyGlobal;

  useEffect(() => {
    treeHierarchyGlobal.length === 0 && refetchRepoObjects();
    setFlattenTree(getFlattenedTree(treeHierarchyGlobal));
  }, []);

  useEffect(() => {
    filesSelection &&
      filesSelection.length > 0 &&
      fileType === FileType.Input &&
      setSelected(filesSelection);

    filesSelection && setSelected(filesSelection);
  }, []);

  const isLoadingConditions = [
    isLoadingGetRepoObjects,
    treeHierarchyGlobal.length <= 0,
    ...(props.parentLoading ?? [])
  ];
  const isLoading = isLoadingConditions.some((condition) => condition);

  const handleToggle = (event: any, nodeIds: any) => {
    event.persist();
    if (typeof event.target.checked == "boolean") {
      setSelected(selected);
    } else {
      setExpanded(nodeIds);
      setSelected(selected);
    }
  };

  const getChildFiles = (repoName: string, directoryPath: string): any[] => {
    const tree = flattenTree
      .filter(
        (file) =>
          file.repo?.name === repoName &&
          `${file.repo?.name}/${file.absolutePath}`.includes(
            `${repoName}/${directoryPath}`
          )
      )
      .map((file) => ({
        repositoryId: file.repo.id,
        versionId: file.versionId ? file.versionId : "",
        absolutePath: file.repo.name + "/" + file.absolutePath
      }));

    return tree;
  };

  const handleChangeEvent = (node: any) => {
    let allNodes: any[] = selected;

    if (node.isDir) {
      let files = getChildFiles(node.repo.name, node.absolutePath);

      if (
        selected.some((ai: any) =>
          files.some((a2) => a2.absolutePath === ai.absolutePath)
        )
      ) {
        // remove
        allNodes = selected.filter(
          (x: any) =>
            !files.some((x2: any) => x2.absolutePath === x.absolutePath)
        );
      } else {
        allNodes = allNodes.concat(files);
      }
    } else {
      if (
        selected.some((ai: any) =>
          ai.absolutePath.includes(`${node.repo.name}/${node.absolutePath}`)
        )
      ) {
        allNodes = selected.filter(
          (x: any) =>
            !x.absolutePath.includes(`${node.repo.name}/${node.absolutePath}`)
        );
      } else {
        allNodes = allNodes.concat({
          repositoryId: node.repo.id,
          versionId: node.versionId ? node.versionId : "",
          absolutePath: node.repo.name + "/" + node.absolutePath
        });
      }
    }
    setSelected(allNodes);
    updateFileSelection(fileType, allNodes);
  };

  const handleRadioChangeEvent = (node: any) => {
    if (fileType === FileType.Script || fileType === FileType.CompareView) {
      if (!node.isDir) {
        updateFileSelection(fileType, {
          repositoryId: node.repo.id,
          versionId: node.versionId,
          absolutePath: `${node.repo.name}/${node.absolutePath}`
        });
        setSelected(`${node.repo.name}/${node.absolutePath}`);
      }
    } else if (fileType === FileType.WorkingDir) {
      if (typeof node == "string") {
        updateFileSelection(fileType, `${node}/`);
        setSelected(`${node}/`);
      } else {
        updateFileSelection(fileType, `${node.repo.name}/${node.absolutePath}`);
        setSelected(`${node.repo.name}/${node.absolutePath}`);
      }
    }
  };

  const isHidden = (file: RepoObject) => {
    return isFileHidden ? isFileHidden(file) : !file.isDir;
  };

  const renderTreeNodes = (data: any, repoId: string = "") =>
    data.map((item: any) => {
      const label = item.isDir ? (
        <>
          {expanded.includes(`${item.repo?.name}/${item.absolutePath}`) ? (
            <SVGIcons key={item.absolutePath} svgName="folder-open" />
          ) : (
            <Folder key={item.absolutePath} className="folder_icon" />
          )}
          {item.name}
        </>
      ) : (
        <div
          data-versionid={item.versionId}
          data-absolutepath={item.absolutePath}
        >
          <FileIcon
            fileName={item.name}
            overallValidityStatus={
              ValidityStatus[
                item.validity
                  ?.overallValidityStatus as unknown as ValidityStatusType
              ]
            }
            fontSize={"small"}
            absolutePath={item.absolutePath}
          />{" "}
          {item.name}
        </div>
      );

      if (item.content && item.content.length > 0) {
        return (
          <StyledTreeItem
            key={item.absolutePath}
            itemId={`${item.repo?.name}/${item.absolutePath}`}
            data-testid={"treeItem_" + item.absolutePath}
            label={
              fileType === FileType.Input ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <BpCheckbox
                        checked={selected.some(
                          (x: any) =>
                            x.absolutePath ===
                            `${item.repo?.name}/${item.absolutePath}`
                        )}
                        onChange={() => handleChangeEvent(item)}
                        onClick={(event) => event.stopPropagation()}
                      />
                    }
                    label={label}
                  />
                </FormGroup>
              ) : (
                <FormControl>
                  <RadioGroup>
                    <FormControlLabel
                      value={`${item.repo?.name}/${item.absolutePath}`}
                      control={
                        <Radio
                          disableRipple
                          className="ms-4 p-1"
                          onChange={() => handleRadioChangeEvent(item)}
                          size="small"
                          checked={
                            selected ===
                            `${item.repo?.name}/${item.absolutePath}`
                          }
                          value={`${item.repo?.name}/${item.absolutePath}`}
                          hidden={isHidden(item)}
                        />
                      }
                      label={label}
                    />
                  </RadioGroup>
                </FormControl>
              )
            }
          >
            {renderTreeNodes(item.content, repoId)}
          </StyledTreeItem>
        );
      }

      return (fileType === FileType.WorkingDir && item.isDir) ||
        fileType !== FileType.WorkingDir ? (
        <StyledTreeItem
          key={item.absolutePath}
          itemId={`${item.repo?.name}/${item.absolutePath}`}
          label={
            fileType === FileType.Input ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <BpCheckbox
                      checked={selected.some(
                        (x: any) =>
                          x.absolutePath ===
                          `${item.repo?.name}/${item.absolutePath}`
                      )}
                      onChange={() => handleChangeEvent(item)}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  }
                  label={label}
                />
              </FormGroup>
            ) : (
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value={`${item.repo?.name}/${item.absolutePath}`}
                    control={
                      <Radio
                        onChange={() => handleRadioChangeEvent(item)}
                        size="small"
                        className="p-1 ms-4"
                        disableRipple
                        checked={
                          selected === `${item.repo?.name}/${item.absolutePath}`
                        }
                        value={`${item.repo?.name}/${item.absolutePath}`}
                        hidden={isHidden(item)}
                      />
                    }
                    label={label}
                  />
                </RadioGroup>
              </FormControl>
            )
          }
        ></StyledTreeItem>
      ) : (
        <></>
      );
    });

  return isLoading ? (
    <div className="center_div">
      <Spinner animation="border" className="spinner_color" />
      <p>Loading...</p>
    </div>
  ) : (
    <>
      {treeData?.map((repo: GetRepoObjectQueryData) => {
        return (
          <SimpleTreeView
            key={`${repo.repo.name}-tree`}
            className="mt-2 tree-view"
            slots={{
              expandIcon: AddBoxOutlinedIcon,
              collapseIcon: IndeterminateCheckBoxOutlinedIcon
            }}
            onExpandedItemsChange={handleToggle}
            defaultExpandedItems={["/"]}
            expandedItems={expanded}
            selectedItems={selected}
          >
            <StyledTreeItem
              key={"/"}
              itemId={"/"}
              label={
                <>
                  {fileType === FileType.WorkingDir ? (
                    <RadioGroup>
                      <FormControlLabel
                        value={`${repo.repo.name}/`}
                        control={
                          <Radio
                            disableRipple
                            className="p-1"
                            onChange={(event) =>
                              handleRadioChangeEvent(event.target.value)
                            }
                            size="small"
                            checked={selected === `${repo.repo.name}/`}
                            value={repo.repo.name}
                          />
                        }
                        label={repo.repo.name}
                      />
                    </RadioGroup>
                  ) : (
                    <>
                      <CenterFocusStrongIcon className="grey_color" />
                      {repo.repo.name}
                    </>
                  )}
                </>
              }
            >
              {renderTreeNodes(repo.repoObjects, repo.repo.id)}
            </StyledTreeItem>
          </SimpleTreeView>
        );
      })}
    </>
  );
}
