import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  ApolloLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthenticatedApp from "./components/authenticatedApp";
import { useAuth } from "./components/authContextProvider";
import Footer from "./components/footer";
import NavBar from "./components/navbar/navbar";
import NavBarExternal from "./components/navbar/navbarExternal";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { createAuthLink, AUTH_TYPE } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { BrowserRouter, Route } from "react-router-dom";
import ErrorBoundary from "./components/abstractComponents/errorBoundary";
import ExternalDownloadLink from "./components/externalLink/externalDownloadLink";
import ExternalUploadLink from "./components/externalLink/externalUploadLink";
import { ValidityStatus, ValidityStatusType } from "./backend/types";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY!);

function AnonymousApp(): JSX.Element {
  return <div className="App">You are not signed in, please wait...</div>;
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#02739c"
    },
    info: {
      main: "#77c9e7"
    }
  }
});

function App() {
  const dataIdFromObject = (o: any) => {
    if (o.__typename === "ActionFile") {
      return `${o.__typename}-${o.file.name}-${o.versionId}-${
        o.actionFileType
      }-${
        ValidityStatus[o?.validity?.overallValidityStatus as ValidityStatusType]
      }`;
    }
    if (o.__typename === "User") {
      return `${o.__typename}-${o.email}`;
    }
    if (o.__typename === "Group") {
      return `${o.__typename}-${o.email}`;
    }
    if (o.__typename === "RepoObject") {
      return `${o.__typename}-${o.versionId}-${o.name}-${
        ValidityStatus[o?.validity?.overallValidityStatus as ValidityStatusType]
      }-${o.qualityCheckStatus}`;
    }
    if (o.__typename === "DownloadLink") {
      return `${o.__typename}-${o.url}`;
    }

    return o.name
      ? `${o.__typename}-${o.name}-${o.id}`
      : o.id
        ? `${o.__typename}-${o.id}`
        : JSON.stringify(o);
  };

  const { isSignedIn, token } = useAuth();

  const url = process.env.REACT_APP_SUBSCRIPTION_URL!;
  const region = process.env.REACT_APP_REGION!;
  const auth = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => token
  };
  const authApi = {
    type: AUTH_TYPE.API_KEY,
    apiKey: process.env.REACT_APP_APPSYNC_API_KEY
  };

  const httpLinkSubscription = createHttpLink({ uri: url });

  const linkSubscription = ApolloLink.from([
    // @ts-ignore
    createAuthLink({ url, region, auth }),
    // @ts-ignore
    createSubscriptionHandshakeLink(
      { url: url, region, auth }, //we might need to put url + "/realtime" back, if notifications stop working
      httpLinkSubscription
    )
  ]);

  const linkApi = ApolloLink.from([
    // @ts-ignore
    createAuthLink({ url, region, auth: authApi }),
    // @ts-ignore
    createHttpLink({
      uri: url
    })
  ]);

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}` || "http://localhost:8000/"
  });

  const httpLinkInteractive = createHttpLink({
    uri:
      `${process.env.REACT_APP_INTERACTIVE_API_URL}` || "http://localhost:9000/"
  });

  const httpLinkExecution = createHttpLink({
    uri:
      `${process.env.REACT_APP_EXECUTION_API_URL}` || "http://localhost:8001/"
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });

  //we split up the client for the two types of calls. Appsync and Classical one
  const client = new ApolloClient({
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "appsync", // Routes the query to the proper client
      linkSubscription,
      ApolloLink.split(
        (operation) => operation.getContext().clientName === "appsyncapi",
        linkApi,
        ApolloLink.split(
          (operation) => operation.getContext().clientName === "interactive",
          authLink.concat(httpLinkInteractive),
          ApolloLink.split(
            (operation) => operation.getContext().clientName === "execution",
            authLink.concat(httpLinkExecution),
            authLink.concat(httpLink)
          )
        )
      )
    ),
    cache: new InMemoryCache({
      dataIdFromObject,
      addTypename: false
    })
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <ApolloProvider client={client}>
        {isSignedIn ? (
          <>
            <BrowserRouter>
              <NavBar />
              <div className="container-fluid">
                <div className="row flex-nowrap">
                  <AuthenticatedApp />
                </div>
              </div>
            </BrowserRouter>
          </>
        ) : (
          <BrowserRouter>
            <NavBarExternal />
            <div className="app_content">
              <Route path="/externalDownload/:downloadId">
                <ErrorBoundary>
                  <ExternalDownloadLink />
                </ErrorBoundary>
              </Route>
              <Route path="/externalUpload/:uploadId">
                <ErrorBoundary>
                  <ExternalUploadLink />
                </ErrorBoundary>
              </Route>
              <Route path="/" exact>
                <AnonymousApp />
              </Route>
            </div>
          </BrowserRouter>
        )}
        <Footer />
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
