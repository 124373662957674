import { useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
  Table
} from "react-bootstrap";
import { useCreateUserValidation } from "../../backend/hooks/pmxActivity/qualityCheck/mutationCreateUserValidation";
import { useGetUserValidations } from "../../backend/hooks/pmxActivity/qualityCheck/queryGetUserValidations";
import { ValidityStatusType } from "../../backend/types";
import Error from "../abstractComponents/error";

// Component that appears when we go to a file inside ot Table Veiw -> Select a file -> Properties
export default function UserFileValidity(props: { versionId: String }) {
  const [userValidityDescription, setUserValidityDescription] = useState("");
  const [selectedUserValidity, setSelectedUserValidity] = useState("");

  const {
    createUserValidation,
    isLoadingCreateUserValidation,
    errorCreateUserValidation
  } = useCreateUserValidation();

  const {
    userValidations,
    refetchUserValidation,
    isLoadingValidationQuery,
    errorValidationQuery
  } = useGetUserValidations(props.versionId);

  const saveUserValidity = () => {
    createUserValidation({
      variables: {
        versionId: props.versionId,
        validityStatus: selectedUserValidity as ValidityStatusType,
        description: userValidityDescription
      }
    }).then(() => {
      refetchUserValidation();
      setSelectedUserValidity("");
      setUserValidityDescription("");
    });
  };

  return (
    <>
      <Row>
        <Col xs={2}>Status</Col>
        <Col xs={4}>
          <Form.Select
            aria-label="Status"
            value={selectedUserValidity}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              event.preventDefault();
              setSelectedUserValidity(event.target.value);
            }}
          >
            <option value="">Default</option>
            <option value="VALID">Valid</option>
            <option value="INVALID">Invalid</option>
            <option value="NOT_SET">Not Set</option>
          </Form.Select>
        </Col>
        <Col xs={1}>
          {isLoadingCreateUserValidation && (
            <Spinner animation="border" className="spinner_color" size="sm" />
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={2}>Description</Col>
        <Col xs={4}>
          <FloatingLabel controlId="DescriptionFloatArea" label="Description">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              value={userValidityDescription}
              onChange={(e) => setUserValidityDescription(e.target.value)}
            />
          </FloatingLabel>
        </Col>
        <Col xs={1}>
          <Button
            disabled={selectedUserValidity === ""}
            onClick={saveUserValidity}
          >
            Save
          </Button>
        </Col>
      </Row>
      {isLoadingValidationQuery && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      {userValidations &&
      userValidations?.getUserValidations?.userValidations?.length === 0 ? (
        <p>No History found</p>
      ) : (
        <>
          <br />
          <h4>History</h4>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Revision</th>
                <th>Date</th>
                <th>User</th>
                <th>Status</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {userValidations?.getUserValidations?.userValidations?.map(
                (validation: any) => (
                  <tr key={validation.id}>
                    <td>{validation.revision}</td>
                    <td>{validation.created}</td>
                    <td>{validation.createdBy}</td>
                    <td>{validation.validityStatus}</td>
                    <td>{validation.validityStatus}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
          {(errorValidationQuery || errorCreateUserValidation) && (
            <Error error={errorValidationQuery || errorCreateUserValidation} />
          )}
        </>
      )}
    </>
  );
}
