import { Col, Row, Spinner } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import {
  RepoObject,
  ValidityStatus,
  ValidityStatusType
} from "../../backend/types";
import { useContext, useEffect, useState } from "react";
import { FileSelectModal } from "../abstractComponents/fileSelectModal";
import { FileType } from "../abstractComponents/fileTreeView";
import { useHistoryVersionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetHistoryVersions";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import {
  compressedFileExtensions,
  excelFormatsExtensions,
  imageExtensions,
  installersExtensions,
  isFileEndsWith,
  moviesExtensions
} from "../../helpers/fileHelper";

export class FileObjectState {
  versionId!: string;
  repoId!: number;
  name!: string;
  allVersions!: RepoObject[];
}

interface FileSelectProps {
  identifier: string;
  fileObject: FileObjectState;
  updateFileObject: (identifier: string, fileObject: FileObjectState) => void;
}

export function FileSelect(props: FileSelectProps) {
  const { repos, isLoadingGetRepoObjects, refetchRepoObjects } = useContext(
    BrowsePmxActivityContext
  );
  const [modalConfig, setModalConfig] = useState<{
    open: boolean;
    fileType?: FileType;
    text?: string | any;
  }>({
    open: false
  });

  const [temporaryValue, setTemporaryValue] = useState<any>();
  const updateFileSelectionTemporary = (fileType: FileType, node: any) => {
    if (fileType === FileType.CompareView) {
      setTemporaryValue({
        repoId: node.repositoryId,
        versionId: node.versionId,
        absolutePath: node.absolutePath
      });
    }
  };

  const { getFileVersions, isLoading } = useHistoryVersionsLazyQuery();

  const isFileHidden = (file: RepoObject) => {
    const excludedExtensions = [
      imageExtensions,
      compressedFileExtensions,
      moviesExtensions,
      installersExtensions,
      excelFormatsExtensions
    ].flat();
    excludedExtensions.push(".pdf");
    return file.isDir || isFileEndsWith(file.name, excludedExtensions);
  };

  useEffect(() => {
    refetchRepoObjects();
  }, []);

  useEffect(() => {
    if (repos.length === 0) return;
    getFileVersions({
      variables: {
        repoId: props.fileObject.repoId,
        versionId: props.fileObject.versionId
      }
    }).then(({ data }) => {
      const file = data.getHistoryVersions.find(
        (file: RepoObject) => file.versionId === props.fileObject.versionId
      );
      const repoName = repos.find(
        (repoObj: any) => repoObj.repo.id === props.fileObject.repoId
      )?.repo.name;
      const foundFileName = `${repoName}/${file.name}`;
      props.updateFileObject(props.identifier, {
        ...props.fileObject,
        name: foundFileName,
        allVersions: data.getHistoryVersions
      });
    });
  }, [repos]);

  const updateInput = (event: SelectChangeEvent) => {
    props.updateFileObject(props.identifier, {
      ...props.fileObject,
      versionId: event.target.value
    });
  };

  const onConfirmModal = () => {
    if (temporaryValue.versionId !== props.fileObject.versionId) {
      updateFileVersion();
    }
  };

  const updateFileVersion = () => {
    getFileVersions({
      variables: {
        repoId: temporaryValue.repoId,
        versionId: temporaryValue.versionId
      }
    }).then(({ data }) => {
      props.updateFileObject(props.identifier, {
        versionId: temporaryValue.versionId,
        repoId: temporaryValue.repoId,
        name: temporaryValue.absolutePath,
        allVersions: data.getHistoryVersions
      });
    });
  };

  const openModal = (identifier: string) => {
    setModalConfig({
      open: true,
      fileType: FileType.CompareView,
      text: `Select ${identifier} file`
    });
  };

  const fileVersions = props.fileObject.allVersions;
  return (
    <>
      <Col>
        <Col>
          <h5>{props.identifier}</h5>
        </Col>
        {isLoading || isLoadingGetRepoObjects ? (
          <div className="center_div">
            <br />
            <Spinner animation="border" className="spinner_color" />
            <p>Loading...</p>
          </div>
        ) : (
          <>
            <Row className="my-4">
              <TextField
                id={`${props.identifier.toLowerCase()}-file`}
                label={`${props.identifier} File`}
                multiline
                aria-readonly={true}
                value={props.fileObject.name}
                onClick={() => openModal(props.identifier)}
              />
            </Row>
            <Row>
              {fileVersions && (
                <FormControl>
                  <InputLabel>Versions</InputLabel>
                  <Select
                    id={`${props.identifier.toLowerCase()}-version`}
                    defaultValue={""}
                    value={
                      props.fileObject.allVersions.length > 0
                        ? props.fileObject.versionId
                        : ""
                    }
                    label="Version"
                    onChange={(event: SelectChangeEvent) => {
                      updateInput(event);
                    }}
                  >
                    {fileVersions.map((file) => (
                      <MenuItem
                        value={`${file.versionId}`}
                        key={`${props.identifier}-${file.versionId}`}
                        id={`${props.identifier.toLowerCase()}-revision-${
                          file.revision
                        }`}
                      >
                        <Row id={`row-version-${file.versionId}`}>
                          <Typography
                            variant="button"
                            display="block"
                            sx={{ width: "200px" }}
                          >
                            Revision: {file.revision}
                          </Typography>
                          <div className="d-flex gap-4 fs-12px">
                            <p className="mb-0">Created at: {file.created}</p>
                            <p className="mb-0">Created by: {file.createdBy}</p>
                            <p className="mb-0">Size: {file.size} KB</p>
                            <p className="mb-0">
                              Validity:{" "}
                              {
                                ValidityStatus[
                                  file.validity
                                    ?.overallValidityStatus as unknown as ValidityStatusType
                                ]
                              }
                            </p>
                          </div>
                        </Row>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Row>
          </>
        )}
      </Col>
      <FileSelectModal
        setModalAction={setModalConfig}
        updateFileSelection={updateFileSelectionTemporary}
        onConfirmModal={onConfirmModal}
        modelConfig={modalConfig}
        selectedFile={props.fileObject.name}
        isFileHidden={isFileHidden}
      />
    </>
  );
}
