import DownloadingIcon from "@mui/icons-material/Downloading";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useGetActionsQuery } from "../backend/hooks/action/queryGetActions";
import { ActionFile, ActionFileType, ActionStatus } from "../backend/types";
import {
  ActionSource,
  ActionSourceType,
  ActionType,
  ActionTypeType,
  QualityCheckStatus,
  QualityCheckStatusType
} from "../helpers/stringHelper";
import { customFilters } from "../helpers/tableViewHelper";
import { getPreviewURL } from "../helpers/urlHelper";
import Error from "./abstractComponents/error";
import { renderCellExpand } from "./abstractComponents/renderCellExpand";
import EditAction from "./editAction";
import UserManualLink from "./abstractComponents/usermanual";
import { getKeyByValue } from "../helpers/stringHelper";
import { checkIfActionIsNotEditable } from "../helpers/actionHelper";

interface ParamsAction {
  actionId: string;
}
const ELEMENT_ID_PREFIX = "idOfActionDiv";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export function renderActionFiles(
  row: any,
  actionId: string,
  actionFiles: boolean
) {
  return (
    <div
      key={row.id}
      className={
        row.id === actionId
          ? "action_list_element highlited_action_div"
          : "action_list_element"
      }
      id={row.id === actionId ? ELEMENT_ID_PREFIX + actionId : ""}
    >
      {renderActionFileLink(row, actionFiles)}
      {row.hasAdditionalFilesLinked && <div>And More Files...</div>}
    </div>
  );
}

const renderActionFileLink = (
  row: any,
  actionRowLink: boolean
): JSX.Element | string =>
  row.actionFiles
    .sort((af1: ActionFile, af2: ActionFile) => {
      return (
        Object.keys(ActionFileType).indexOf(af1?.actionFileType) -
        Object.keys(ActionFileType).indexOf(af2?.actionFileType)
      );
    })
    .map((af: ActionFile, index: number) => {
      if (actionRowLink) {
        return af ? (
          <div className="no_line_hight" key={`${row.id}-${index}`}>
            <b>
              <a
                href={getPreviewURL(
                  row.pmxActivity.id,
                  af?.file?.versionId!,
                  af?.file?.name!,
                  af?.file?.repo?.id!,
                  true
                )}
              >
                <span className="repo_name_link">{af?.file?.repo?.name}/</span>
                {af?.file?.name} - ({af?.file?.revision})
              </a>
            </b>{" "}
            - {getKeyByValue(ActionFileType, af?.actionFileType)}
          </div>
        ) : (
          <></>
        );
      } else {
        return af?.file?.versionId;
      }
    });

export function Actions() {
  const actionId = (useParams() as ParamsAction).actionId;
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [isPaginationServerSide, setIsPaginationServerSide] = useState(true);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      sortable: !isPaginationServerSide,
      width: 80,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <>
          {params.row.id}{" "}
          <EditAction
            refetchActions={refetchActions}
            action={params.row}
            actionId={params.row.id}
            notEditableAction={checkIfActionIsNotEditable(
              params.row.actionType
            )}
          />
        </>
      )
    },
    {
      field: "actionType",
      headerName: "Type",
      width: 120,
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      valueGetter: (params: GridValueGetterParams) =>
        ActionType[params.row.actionType as ActionTypeType]
    },
    {
      field: "actionFile",
      headerName: "Files",
      width: 600,
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.actionFiles
          .sort((af1: ActionFile, af2: ActionFile) => {
            return (
              Object.keys(ActionFileType).indexOf(af1?.actionFileType) -
              Object.keys(ActionFileType).indexOf(af2?.actionFileType)
            );
          })
          .map(
            (af: ActionFile) =>
              af?.file &&
              af?.file.repo?.name +
                " - (" +
                af?.file.revision +
                ") - " +
                getKeyByValue(ActionFileType, af?.actionFileType)
          )
          .join("; "),
      renderCell: (params: GridRenderCellParams<any, any, any>) =>
        renderActionFiles(params.row, actionId, true)
    },
    {
      field: "actionQualityCheckStatus",
      headerName: "QC Status",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 80,
      valueGetter: (params: GridValueGetterParams) =>
        QualityCheckStatus[
          params.row.actionQualityCheckStatus as QualityCheckStatusType
        ]
    },
    {
      field: "startDatetime",
      headerName: "Executed At",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 120
    },
    {
      field: "userId",
      headerName: "User",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.user.email
    },
    {
      field: "description",
      headerName: "Description",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 200
    },
    {
      field: "action",
      headerName: "Activity",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.pmxActivity && params.row.pmxActivity.trialNumber
    },
    {
      field: "Repo",
      headerName: "Repository",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 160,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.pmxActivity && params.row.pmxActivity.mainRepository.name
    },
    {
      field: "actionSource",
      headerName: "Environment",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 160,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        ActionSource[params.row.actionSource as ActionSourceType]
    },
    {
      field: "actionStatus",
      headerName: "Status",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 120,
      valueGetter: (params: GridValueGetterParams) =>
        getKeyByValue(ActionStatus, params.row.actionStatus)
    },
    {
      field: "actionStatusDescription",
      headerName: "Status Description",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 120,
      renderCell: renderCellExpand
    },
    {
      field: "additionalDetails",
      headerName: "Additional Details/Destination",
      width: 180,
      hide: true,
      filterOperators: customFilters,
      sortable: !isPaginationServerSide
    },
    {
      field: "sourceFolder",
      headerName: "External Folder",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 160
    },
    {
      field: "sourceFiles",
      headerName: "External Files",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      width: 160
    },
    {
      field: "jobId",
      headerName: "Job",
      filterOperators: customFilters,
      sortable: !isPaginationServerSide,
      renderCell: (params: GridRenderCellParams<any, any, any>) =>
        params.row.jobId ? (
          <Link
            to={`/activity/${params.row.pmxActivity.id}/execution/${params.row.jobId}`}
            className="job_id_action"
          >
            {params.row.jobId}
          </Link>
        ) : (
          ""
        )
    },
    {
      field: "historyLabel",
      headerName: "History Label",
      flex: 2,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.historyLabel ? params.row.historyLabel.name : "";
      }
    }
  ];

  const { actions, isLoading, error, refetchActions } = useGetActionsQuery(
    isPaginationServerSide && { pageNumber: page, pageSize }
  );

  const [rowCountState, setRowCountState] = useState(
    actions?.getActions?.totalCount || 0
  );

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "startDatetime",
      sort: "desc" as GridSortDirection
    }
  ]);

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      actions?.getActions?.totalCount !== undefined
        ? actions?.getActions?.totalCount
        : prevRowCountState
    );
  }, [actions?.getActions?.totalCount, setRowCountState]);

  useEffect(() => {
    if (actionId) {
      setTimeout(() => {
        document!
          .getElementById(ELEMENT_ID_PREFIX + actionId)!
          .parentElement!.parentElement!.classList.add(
            "parent_row_selected_action"
          );
        window.location.href = "#" + ELEMENT_ID_PREFIX + actionId;
      }, 200);
    }
  }, [actions]);

  useEffect(() => {
    document.title = "PHIL App - Actions";
  }, []);

  return (
    <div className="app_content">
      <Row>
        <Col xs={10}>
          <div className="d-inline-flex">
            <h2>Actions</h2>
            <UserManualLink
              url="/usermanual/concepts/#actions"
              testId="um-actions"
            />
          </div>
        </Col>
        <Col xs={2}>
          <Button
            onClick={() => setIsPaginationServerSide(false)}
            className="float-end"
            variant="light"
            disabled={!isPaginationServerSide}
          >
            <DownloadingIcon /> Load All Data
          </Button>
        </Col>
      </Row>
      <br />
      {isLoading && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      {!isLoading && !error && (
        <Container fluid>
          <div className="list_repositories_container">
            {actions?.getActions?.actions &&
            actions?.getActions?.actions?.length > 0 ? (
              <DataGridPro
                className={
                  actionId
                    ? "action_data_grid styled_data_grid"
                    : "styled_data_grid"
                }
                rows={actions?.getActions?.actions}
                rowCount={rowCountState}
                columns={columns}
                pageSize={pageSize}
                page={page}
                getRowHeight={() => "auto"}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                pagination
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                autoHeight
                components={{
                  Toolbar: CustomToolbar
                }}
                paginationMode={isPaginationServerSide ? "server" : "client"}
              />
            ) : (
              <div className="no_repositories_available_div">
                No Actions are accessible
              </div>
            )}
          </div>
        </Container>
      )}
      {error && <Error error={error} />}
    </div>
  );
}
