import AddLinkIcon from "@mui/icons-material/AddLink";
import FileIcon from "../../components/abstractComponents/fileIcon";
import MaterialButton from "@mui/material/Button";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
  Spinner,
  ProgressBar,
  NavDropdown
} from "react-bootstrap";
import { useUploadExternalActionHook } from "../../hooks/uploadExternalActionHook";
import Error from "../abstractComponents/error";
import {
  ActionType,
  getActionTypeKeyFromValue,
  getQualityCheckStatusKeyFromValue,
  secondsOfDateFromNow,
  QualityCheckStatus,
  SECONDS_TO_WAIT_FOR_REPO
} from "../../helpers/stringHelper";
import { useContext } from "react";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { ValidityStatus } from "../../backend/types";

const ALLOWED_EXTERNAL_ACTIONS_KEYS = [
  "ADF_CREATION",
  "PLOT_CREATION",
  "REPORT_CREATION",
  "MODEL_EXECUTION",
  "OTHER_ACTION"
];
// ADF upload from within Table View
export default function UploadAdf(props: {
  currentSelectedPath: string;
  disabled: boolean;
  refetchRepoObjects: Function;
  currentSubArray: any;
  repository: any;
}) {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);

  const {
    saveFilesOutput,
    isLoading,
    setIsUploadModalVisible,
    isUploadModalVisible,
    closeModal,
    wasMutationCalled,
    createActionLoading,
    uploadLoading,
    filesToBeUploaded,
    filesHashedOutput,
    removeCurrentFileFromOutput,
    actionType,
    changeActionType,
    saveFilesDescribing,
    filesHashedDescribing,
    removeCurrentFileFromDescribing,
    saveInputFiles,
    removeCurrentFileFromInput,
    filesHashedInput,
    uncompress,
    setUncompress,
    description,
    setDescription,
    qualityCheckStatus,
    importAll,
    createActionError,
    uploadLinksError,
    maxNumberOfUploads,
    changeQualityCheckedStatus,
    treeHierarchyGlobal,
    repoObjectError,
    errorConsumingActions,
    isRepoObjectLoading,
    isLoadingConsumingActions
  } = useUploadExternalActionHook(
    props.currentSelectedPath,
    activityId,
    props.refetchRepoObjects,
    props.currentSubArray
  );

  return (
    <>
      <NavDropdown.Item
        size="small"
        data-testid="uploadAdfItem"
        onClick={() => {
          setIsUploadModalVisible(true);
        }}
        disabled={props.disabled}
        title={
          !treeHierarchyGlobal
            ? "No files in repository at the moment"
            : "Upload Analysis Data Files"
        }
        id="uploadAdfItem"
      >
        <AddLinkIcon /> Upload External Action
      </NavDropdown.Item>
      <Modal
        show={isUploadModalVisible}
        onHide={closeModal}
        dialogClassName="modal-80w"
        aria-labelledby="uploadAdfModalTitle"
      >
        <Modal.Header closeButton={!wasMutationCalled}>
          <Modal.Title id="uploadAdfModalTitle">
            Upload External Action into &quot;{activityData.trialNumber}/
            {props.currentSelectedPath}&quot;
            {isLoading && (
              <Spinner animation="border" className="spinner_color" />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {createActionLoading || uploadLoading ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Uploading files...</p>
              </div>
            ) : (
              <>
                <Row>
                  <Col xs={5}>
                    <div>
                      <h3>New Result Files</h3>
                      <Form.Control
                        type="file"
                        required
                        name="file"
                        onChange={saveFilesOutput}
                        multiple
                        data-testid="adfOutputFileFormControl"
                        id="adfOutputFileFormControl"
                        disabled={
                          wasMutationCalled ||
                          isLoading ||
                          isRepoObjectLoading ||
                          isLoadingConsumingActions
                        }
                      />
                      {filesHashedOutput.map((file) => (
                        <div
                          data-testid="fileOutputElementAdf"
                          key={"output-" + file.hash + file.file}
                          className={
                            file.willOverwrite
                              ? "drop_comment_file drop_comment_file_warning"
                              : "drop_comment_file"
                          }
                        >
                          <FileIcon
                            fileName={file.file}
                            overallValidityStatus={ValidityStatus.NOT_SET}
                            fontSize={"small"}
                          />{" "}
                          {file.file +
                            (file.willOverwrite
                              ? " (will overwrite file)"
                              : "")}
                          <MaterialButton
                            variant="text"
                            onClick={() =>
                              removeCurrentFileFromOutput(file.file)
                            }
                            id="deleteRepliedToComment"
                            className="float-end small_mui_button"
                            role="close_adf_output_file"
                            size="small"
                          >
                            X
                          </MaterialButton>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col xs={7} data-testid="inputFilesColumn">
                    <h3>Input Files from PHIL</h3>
                    <Form.Control
                      type="file"
                      required
                      name="file"
                      onChange={saveInputFiles}
                      multiple
                      data-testid="adfInputFileSelectFormControl"
                      id="adfInputFileSelectFormControl"
                      disabled={wasMutationCalled || isLoading}
                    />
                    {filesHashedInput.map((file) => (
                      <div
                        data-testid="fileInputElementAdf"
                        key={"input-" + file.hash + file.file}
                        className={
                          !file.found
                            ? "drop_comment_file drop_comment_file_invalid"
                            : !file.downloaded
                              ? "drop_comment_file drop_comment_file_warning"
                              : "drop_comment_file"
                        }
                      >
                        <FileIcon
                          fileName={file.file}
                          overallValidityStatus={ValidityStatus.VALID}
                          fontSize={"small"}
                        />
                        {file.repo?.name +
                          "/" +
                          file.absolutePath +
                          (!file.found
                            ? " (not found)"
                            : !file.downloaded
                              ? " (not downloaded before)"
                              : "")}
                        {file.lastDownloadedDate
                          ? " (Downloaded: " + file.lastDownloadedDate + ")"
                          : ""}
                        <MaterialButton
                          variant="text"
                          onClick={() => removeCurrentFileFromInput(file.file)}
                          id="deleteRepliedToComment"
                          className="float-end small_mui_button"
                          role="close_adf_input_file"
                          size="small"
                        >
                          X
                        </MaterialButton>
                      </div>
                    ))}
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col xs={5}>
                    <h3>Describing Files</h3>
                    <Form.Control
                      type="file"
                      required
                      name="file"
                      onChange={saveFilesDescribing}
                      multiple
                      data-testid="adfDescriptionFileSelectFormControl"
                      id="adfDescriptionFileSelectFormControl"
                      disabled={wasMutationCalled}
                    />
                    {filesHashedDescribing.map((file) => (
                      <div
                        data-testid="fileDescriptionElementAdf"
                        key={"description-" + file.hash + file.file}
                        className={
                          file.willOverwrite
                            ? "drop_comment_file drop_comment_file_warning"
                            : "drop_comment_file"
                        }
                      >
                        <FileIcon
                          fileName={file.file}
                          overallValidityStatus={ValidityStatus.NOT_SET}
                          fontSize={"small"}
                        />{" "}
                        {file.file +
                          (file.willOverwrite ? " (will overwrite file)" : "")}
                        <MaterialButton
                          variant="text"
                          onClick={() =>
                            removeCurrentFileFromDescribing(file.file)
                          }
                          id="deleteRepliedToComment"
                          className="float-end small_mui_button"
                          role="close_adf_describing_file"
                          size="small"
                        >
                          X
                        </MaterialButton>
                      </div>
                    ))}
                  </Col>
                  <Col xs={3}>
                    {(isRepoObjectLoading ||
                      isLoading ||
                      isLoadingConsumingActions) && (
                      <Spinner
                        animation="border"
                        size="sm"
                        variant="secondary"
                      />
                    )}
                  </Col>
                  <Col xs={2}>
                    <h3>
                      Action Type<span className="red_form">*</span>
                    </h3>
                    <Form.Select
                      value={getActionTypeKeyFromValue(actionType)}
                      onChange={changeActionType}
                      key="adf_action_type"
                      id="adfActionType"
                      data-testid="adfActionType"
                    >
                      <option>--Please Select--</option>
                      {Object.entries(ActionType)
                        .filter((element) =>
                          ALLOWED_EXTERNAL_ACTIONS_KEYS.includes(element[0])
                        )
                        .map((element) => (
                          <option key={element[0]} value={element[0]}>
                            {element[1]}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                </Row>
                <br />
                <br />
                {(filesHashedOutput.filter((file: any) => {
                  return file.file.endsWith(".zip");
                }).length > 0 ||
                  filesHashedDescribing.filter((file: any) => {
                    return file.file.endsWith(".zip");
                  }).length > 0) && (
                  <Row>
                    <Col xs={3}>
                      <Form.Label>
                        Uncompress on Upload?{" "}
                        {secondsOfDateFromNow(props.repository.created) <
                          SECONDS_TO_WAIT_FOR_REPO && (
                          <span className="red_form">
                            (you have to wait for{" "}
                            {SECONDS_TO_WAIT_FOR_REPO -
                              secondsOfDateFromNow(
                                props.repository.created
                              )}{" "}
                            more seconds until the repositoy is ready)
                          </span>
                        )}
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        disabled={
                          secondsOfDateFromNow(props.repository.created) <
                            SECONDS_TO_WAIT_FOR_REPO || wasMutationCalled
                        }
                        checked={uncompress}
                        id="uncompressUploadValidAdfCheckbox"
                        onChange={() => setUncompress(!uncompress)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={3}>
                    <Form.Label>
                      Upload Description<span className="red_form">*</span>
                    </Form.Label>
                  </Col>
                  <Col xs={3} md={4}>
                    <FormControl
                      as="textarea"
                      required
                      data-testid="descriptionAdfUploadFormControl"
                      placeholder="Enter description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      id="descriptionAdfUploadFormControl"
                      disabled={wasMutationCalled}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs={3}>
                    <Form.Label>Quality Controll Status</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      value={String(
                        getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                      )}
                      onChange={changeQualityCheckedStatus}
                      key="adf_quality_controlled"
                      id="adfQualityControlled"
                    >
                      {Object.entries(QualityCheckStatus).map((element) => (
                        <option key={element[0]} value={element[0]}>
                          {element[1]}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col xs={2}>
                    <Button
                      variant="secondary"
                      id="cancelAdfUploadButton"
                      data-testid="close_adf_input_file"
                      onClick={closeModal}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs={6}></Col>
                  <Col xs={4}>
                    {wasMutationCalled ? (
                      <div className="center_div">
                        <Spinner animation="border" className="spinner_color" />
                        <br />
                        Uploading... Do not close the window
                        {maxNumberOfUploads > 1 && (
                          <>
                            <ProgressBar
                              animated
                              now={
                                //We add one so it does not show an empty rectangle
                                ((maxNumberOfUploads -
                                  (filesToBeUploaded > 0
                                    ? filesToBeUploaded
                                    : 0)) /
                                  maxNumberOfUploads) *
                                100
                              }
                              label={`${Math.round(
                                ((maxNumberOfUploads -
                                  (filesToBeUploaded > 0
                                    ? filesToBeUploaded
                                    : 0)) /
                                  maxNumberOfUploads) *
                                  100
                              )}%`}
                            />
                            {filesToBeUploaded && maxNumberOfUploads && (
                              <p className="uploading_info_details">
                                In queue:{" "}
                                {filesToBeUploaded > 0 ? filesToBeUploaded : 0}/
                                {maxNumberOfUploads} file parts left
                              </p>
                            )}
                            <br />
                            <br />
                          </>
                        )}
                      </div>
                    ) : (
                      <Button
                        variant="primary"
                        data-testid="finishAdfUploadButton"
                        id="finishAdfUploadButton"
                        className="float-end"
                        onClick={importAll}
                        disabled={
                          isLoading ||
                          isRepoObjectLoading ||
                          isLoadingConsumingActions ||
                          actionType === undefined ||
                          filesHashedInput.filter((file) => !file.found)
                            .length > 0 ||
                          (filesHashedInput.length === 0 &&
                            (filesHashedOutput.length === 0 ||
                              filesHashedDescribing.length === 0)) ||
                          description === ""
                        }
                      >
                        Upload
                      </Button>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
      {(createActionError ||
        uploadLinksError ||
        repoObjectError ||
        errorConsumingActions) && (
        <Error
          error={
            createActionError ||
            uploadLinksError ||
            repoObjectError ||
            errorConsumingActions
          }
        />
      )}
    </>
  );
}
