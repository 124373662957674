import { Col, Container, Row, Spinner, Button, Form } from "react-bootstrap";
import Error from "../abstractComponents/error";
import { useParams } from "react-router-dom";
import FileIcon from "../../components/abstractComponents/fileIcon";
import MaterialButton from "@mui/material/Button";
import { ActionFileType, ValidityStatus } from "../../backend/types";
import { getChecksums } from "../../helpers/fileHelper";
import {
  getQualityCheckStatusKeyFromValue,
  QualityCheckStatus,
  QualityCheckStatusType
} from "../../helpers/stringHelper";
import { useEffect, useState } from "react";
import { useDownloadExternalLinkQuery } from "../../backend/hooks/externalLink/queryGetExternalUploadLinks";

interface ParamsDownload {
  uploadId: string;
}

// Create Download Link, that can be used by external Users
export default function ExternalDownloadLink() {
  const uploadId = (useParams() as ParamsDownload).uploadId;

  const { getUploadLink, uploadData, errorUpload, uploadIsLoading } =
    useDownloadExternalLinkQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState(0);
  const [overwriteError, setOverwriteError] = useState("");
  const [filesHashed, setFilesHashed] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [description, setDescription] = useState("");
  const [uncompress, setUncompress] = useState(false);
  const [errorFromUploading, setErrorFromUploading] = useState("");
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus>(QualityCheckStatus.NOT_STARTED);
  const [uploadHasFinished, setUploadHasFinished] = useState(false);

  const saveFiles = (e: any) => {
    setIsLoading(true);
    setOverwriteError("");
    getChecksums(e).then((array) => {
      setFilesHashed((prevFiles) => {
        return [...prevFiles, ...array];
      });

      //reset the input element
      const inputElement = document.getElementById(
        "formFile"
      ) as HTMLInputElement | null;
      if (inputElement) {
        inputElement.value = "";
      }
      setIsLoading(false);
    });

    setFiles((prevFiles) => {
      return [...prevFiles, ...e["target"]["files"]];
    });
  };

  const removeCurrentFileFromFiles = (fileName: string) => {
    setFiles((prevFiles) => {
      return [...prevFiles.filter((file) => file.name !== fileName)];
    });

    setFilesHashed((prevFiles) => {
      return [...prevFiles.filter((file) => file.file !== fileName)];
    });
  };

  const uploadFiles = () => {
    const fileList: any[] = [];
    const hashList: any[] = [];
    const actionFileTypes: any[] = [];

    // We first start with the small files
    filesHashed.forEach((file) => {
      fileList.push(file.file);
      hashList.push(file.hash);
      actionFileTypes.push(ActionFileType.Output);
    });

    setFilesToBeUploaded((prevState) => prevState + hashList.length);

    if (fileList.length) {
      getUploadLink({
        variables: {
          id: uploadId,
          absolutePaths: fileList,
          md5sums: hashList,
          qualityCheckStatus:
            getQualityCheckStatusKeyFromValue(qualityCheckStatus),
          actionFileTypes,
          uncompressOnUpload: uncompress,
          description: description
        }
      });
    }
  };

  //Runs after upload links are pulled
  useEffect(() => {
    if (typeof uploadData !== "undefined") {
      setIsLoading(true);
      const promiseArray: any = [];
      uploadData.getExternalUploadLinks?.presignedUrls.forEach(
        async (uploadDataParse: any) => {
          const fields = JSON.parse(uploadDataParse.fields);
          const formData = new FormData();
          Object.entries(fields).forEach((element: any) => {
            const [key, value] = element;
            formData.append(key, value);
          });
          //Attach the proper file to the form
          formData.append(
            "file",
            Array.from(files).find((file) => {
              return fields.key.endsWith(file.name);
            })
          );
          promiseArray.push(
            fetch(uploadDataParse.url, {
              method: "POST",
              body: formData
            }).then(() => {
              setFilesToBeUploaded((prevState) => prevState - 1);
            })
          );
        }
      );

      Promise.all(promiseArray)
        .finally(() => {
          setUploadHasFinished(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setErrorFromUploading("Error From Uploading");
          console.error(err);
          setUploadHasFinished(true);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadData]);

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatus[event.target.value as QualityCheckStatusType]
    );
  }

  return (
    <>
      <Container fluid>
        {(uploadIsLoading || isLoading) && (
          <div className="center_div">
            <Spinner animation="border" className="spinner_color" />
            <p>
              {uploadIsLoading
                ? "Generating Link..."
                : "Uploading... Please do not close the page..."}
            </p>
          </div>
        )}
        {!uploadHasFinished ? (
          <>
            <h3>Welcome to PHIL Upload Utility</h3>
            <Row>
              <Col>
                <form
                  encType="multipart/form-data"
                  method="POST"
                  id="uploadFileForm"
                >
                  <Form.Group className="mb-3">
                    <Form.Label>
                      File <span className="red_form">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={isLoading}
                      type="file"
                      required
                      name="file"
                      onChange={saveFiles}
                      id="uploadFileInputExternal"
                      multiple
                    />
                  </Form.Group>
                  {files.map((file) => (
                    <div
                      data-testid="fileOutputAdfFormElement"
                      key={file.name}
                      className={
                        file.willOverwrite
                          ? "drop_comment_file drop_comment_file_invalid"
                          : "drop_comment_file"
                      }
                    >
                      <FileIcon
                        fileName={file.name}
                        overallValidityStatus={ValidityStatus.NOT_SET}
                        fontSize={"small"}
                      />{" "}
                      {file.name}
                      <MaterialButton
                        variant="text"
                        onClick={() => removeCurrentFileFromFiles(file.name)}
                        id="deleteRepliedToCommentButton"
                        className="float-end small_mui_button"
                        size="small"
                      >
                        X
                      </MaterialButton>
                    </div>
                  ))}

                  {filesHashed.filter((file: any) => {
                    return file.file.endsWith(".zip");
                  }).length > 0 && (
                    <>
                      <br />
                      <Row>
                        <Col>
                          <Form.Label>Uncompress on Upload? </Form.Label>
                        </Col>
                        <Col>
                          <Form.Check
                            type="checkbox"
                            checked={uncompress}
                            id="uncompressUploadValidCheckbox"
                            onChange={() => setUncompress(!uncompress)}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <br />
                  <Form.Label>Upload Description</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    data-testid="uploadDescriptionFormElement"
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    id="uploadDescriptionFormElement"
                  />
                  <br />
                  <Row>
                    <Col xs={6}>
                      <Form.Label>Quality Controll Status</Form.Label>
                    </Col>
                    <Col xs={6}>
                      <Form.Select
                        value={String(
                          getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                        )}
                        onChange={changeQualityCheckedStatus}
                        key="upload_quality_controlled"
                        id="uploadQualityControlledFormElement"
                      >
                        {Object.entries(QualityCheckStatus).map((element) => (
                          <option key={element[0]} value={element[0]}>
                            {element[1]}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  {overwriteError !== "" && (
                    <div className="drop_comment_file drop_comment_file_invalid">
                      Error: {overwriteError}
                    </div>
                  )}
                </form>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col>
                <Button
                  className="float-end"
                  variant="primary"
                  disabled={
                    isLoading ||
                    filesHashed.length === 0 ||
                    filesToBeUploaded > 0 ||
                    overwriteError !== ""
                  }
                  id="finishUploadButton"
                  onClick={() => {
                    uploadFiles();
                  }}
                >
                  Upload
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <h1>Upload was Successful!</h1>
        )}
      </Container>
      {(errorUpload || errorFromUploading) && (
        <Error error={errorUpload || errorFromUploading} />
      )}
    </>
  );
}
