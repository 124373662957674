import {
  RepoObject as ImportedRepoObject,
  GetRepoObjectQueryData,
  ValidityStatus
} from "../backend/types";
import {
  TreeItem,
  treeItemClasses,
  TreeItemProps
} from "@mui/x-tree-view/TreeItem";
import { alpha, styled } from "@mui/material/styles";
import FileIcon from "../components/abstractComponents/fileIcon";
import FolderIcon from "@mui/icons-material/Folder";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

export const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 14,
  height: 14,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)"
  }
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 14,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3"
  }
});

// Inspired by blueprintjs
export function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
        "& .MuiSvgIcon-root": { fontSize: 13 },
        pl: 0
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

function dragThis(e: any) {
  e.dataTransfer.setData("versionId", e.target.getAttribute("data-versionid"));
  e.dataTransfer.setData(
    "absolutePath",
    e.target.getAttribute("data-absolutepath")
  );
  e.dataTransfer.setData(
    "overallValidityStatus",
    e.target.getAttribute("data-overallValidityStatus")
  );
}

export const renderTreeNodes = (data: any, repoId: string = "") =>
  data.map((item: any) => {
    const label = item.isDir ? (
      <>
        <FolderIcon className="grey_color folder_icon" />
        {item.name}
      </>
    ) : (
      <div
        draggable="true"
        data-versionid={item.versionId}
        data-absolutepath={item.absolutePath}
        data-overallValidityStatus={item.validity?.overallValidityStatus}
        onDragStart={dragThis}
      >
        <FileIcon
          fileName={item.name}
          overallValidityStatus={item.validity?.overallValidityStatus}
          fontSize={"small"}
          absolutePath={item.absolutePath}
        />
        {item.name}
      </div>
    );
    if (item.content && item.content.length > 0) {
      return (
        <StyledTreeItem
          key={repoId + "__-*-__" + item.absolutePath}
          itemId={repoId + "__-*-__" + item.absolutePath}
          label={label}
        >
          {renderTreeNodes(item.content, repoId)}
        </StyledTreeItem>
      );
    }

    return (
      <StyledTreeItem
        key={repoId + "__-*-__" + item.absolutePath}
        itemId={repoId + "__-*-__" + item.absolutePath}
        label={label}
      />
    );
  });

export const renderTreeNodesInteractive = (
  data: any,
  checkedFolders: Array<string>,
  setCheckedFolders: Function,
  enableFolder: boolean = false
) =>
  data.map((item: any) => {
    let isChecked = checkedFolders.includes(item.path);
    let isDisabled = !enableFolder;
    checkedFolders.forEach((folderName) => {
      if (item.path.startsWith(folderName + "/")) {
        if (!isChecked) {
          isDisabled = true;
        }
        isChecked = true;
      }
    });

    const label = item.isDir ? (
      <>
        <BpCheckbox
          aria-label="Select Folder"
          checked={isChecked}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
          className="folder_select_checkbox"
          onClick={(event) => {
            // removes the checked folder from the list
            if (checkedFolders.includes(item.path)) {
              setCheckedFolders(
                checkedFolders.filter(
                  (folderName: string) => folderName !== item.path
                )
              );
            } else {
              // adds the checked folder to the list
              setCheckedFolders([...checkedFolders, item.path].flat());
            }

            event.stopPropagation();
          }}
          disabled={isDisabled}
        />
        <FolderIcon className="grey_color folder_icon" />
        {item.name}
      </>
    ) : (
      <>
        <FileIcon
          fileName={item.name}
          overallValidityStatus={item.validity?.overallValidityStatus}
          fontSize={"small"}
          absolutePath={item.absolutePath}
        />
        {item.name}
        {item?.state && item.state.toLowerCase() === "added" && (
          <span
            title={
              item.isConflict ? "File Created with conflict" : "File Created"
            }
          >
            <AddCircleOutlineIcon className="interactive_item_state_created float-end" />
            {item.isConflict && (
              <PriorityHighIcon className="interactive_item_state_deleted float-end is_conflict" />
            )}
          </span>
        )}
        {item?.state && item.state.toLowerCase() === "deleted" && (
          <span
            title={
              item.isConflict ? "File Deleted with conflict" : "File Deleted"
            }
          >
            <CancelPresentationIcon className="interactive_item_state_deleted float-end" />
            {item.isConflict && (
              <PriorityHighIcon className="interactive_item_state_deleted float-end is_conflict" />
            )}
          </span>
        )}
        {item?.state && item.state.toLowerCase() === "modified" && (
          <span
            title={
              item.isConflict
                ? "File was Modified with conflict"
                : "File Modified"
            }
          >
            <Rotate90DegreesCcwIcon className="interactive_item_state_modified float-end" />
            {item.isConflict && (
              <PriorityHighIcon className="interactive_item_state_deleted float-end is_conflict" />
            )}
          </span>
        )}
      </>
    );
    const nameVar = item.repoName + "__-*-__" + item.path;
    if (item.content && item.content.length > 0) {
      return (
        <StyledTreeItem itemId={nameVar} key={nameVar} label={label}>
          {renderTreeNodesInteractive(
            item.content,
            checkedFolders,
            setCheckedFolders,
            enableFolder
          )}
        </StyledTreeItem>
      );
    }

    return <StyledTreeItem itemId={nameVar} key={nameVar} label={label} />;
  });

// Tree view of files and folder structure within a repository (But actually rendered within a PMX activity)
export const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3
    }
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 9,
    paddingLeft: 5,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  },
  [`& .${treeItemClasses.content}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2
  }
}));

export interface RepoObject extends ImportedRepoObject {
  content: any[];
}
export default function getTreeHierarchyFromRepoObjectList(
  tree: GetRepoObjectQueryData[]
) {
  const multiTreeStructure: GetRepoObjectQueryData[] = [];

  const organizeTreeHierarchy = (repo: any) => {
    let folderStructure: any = [];

    repo.repoObjects?.forEach((item: RepoObject) => {
      let nameSplit;
      if (item.name.endsWith("/")) {
        //otherwise we will always have an empty folder
        nameSplit = item.name.substring(0, item.name.length - 1).split("/");
      } else {
        nameSplit = item.name.split("/");
      }
      const currentName = String(nameSplit.pop());

      let currentSubArray = folderStructure;
      //for each internal folder, we parse through the tree to look for it

      nameSplit?.forEach((name: string) => {
        const foundObject = currentSubArray.find(
          (element: RepoObject) => element.name === name
        );
        if (foundObject) {
          currentSubArray = foundObject.content;
        } else {
          const absolutePath = item.name.split(name)[0] + name + "/"; //fix for when folder does nto appear as separate object
          currentSubArray.push({
            created: null,
            createdBy: null,
            versionId: item.versionId,
            revision: null,
            md5: null,
            overallValidityStatus: ValidityStatus.NOT_SET,
            size: 0,
            comments: [],
            __typename: "RepoObject",
            name,
            content: [],
            absolutePath: absolutePath,
            isDir: item.name.endsWith("/"),
            repo: {
              ...repo.repo
            }
          });

          currentSubArray = currentSubArray.find(
            (element: any) => element.name === name
          ).content;
        }
      });

      const foundObject = currentSubArray.find(
        (element: any) => element.name === currentName
      );
      if (!foundObject) {
        currentSubArray.push({
          ...item,
          versionId: item?.versionId ? item?.versionId : null,
          name: currentName,
          isDir: item?.name.endsWith("/"),
          absolutePath: item?.name,
          content: [],
          parent: nameSplit.join("/"),
          repo: {
            ...repo.repo
          }
        });
      } else {
        //find and replace objec in Array
        for (let i = 0; i < currentSubArray.length; i++) {
          if (currentSubArray[i].name === currentName) {
            currentSubArray[i] = {
              ...item,
              content: foundObject.content,
              versionId: item?.versionId ? item?.versionId : null,
              parent: nameSplit.join("/"),
              name: currentName,
              absolutePath: item?.name,
              isDir: item?.name.endsWith("/"),
              repo: {
                ...repo.repo
              }
            };
            break;
          }
        }
      }

      currentSubArray = currentSubArray.sort(function (
        a: RepoObject,
        b: RepoObject
      ) {
        if (a.isDir && !b.isDir) {
          return -1;
        }
        if (b.isDir && !a.isDir) {
          return 1;
        }

        return a.name.localeCompare(b.name);
      });
    });

    multiTreeStructure.push({
      repo: {
        id: repo?.repo?.id,
        name: repo?.repo?.name,
        isStandard: repo?.repo?.isStandard
      },
      repoObjects: folderStructure,
      isReadOnlyRepository: repo.isReadOnlyRepository
    });
  };

  tree?.forEach((repo) => {
    organizeTreeHierarchy(repo);
  });

  return multiTreeStructure;
}

export interface flattenObject extends RepoObject {
  name: string;
  isDir: boolean;
  versionId?: string;
  absolutePath: string;
  content: any[]; // TODO: no idea what this is, but it's not used
}

function getRepoObjectContent(repoObjects: flattenObject[]): flattenObject[] {
  let fullFileList: flattenObject[] = [];

  repoObjects?.forEach((repoObject: flattenObject) => {
    fullFileList.push(repoObject);

    if (repoObject.content && repoObject.content.length > 0) {
      fullFileList = [
        ...fullFileList,
        ...getRepoObjectContent(repoObject.content)
      ];
    }
  });

  return fullFileList;
}

export function getFlattenedTree(
  tree: GetRepoObjectQueryData[]
): flattenObject[] {
  let fullFileList: flattenObject[] = [];

  tree?.forEach((repo: GetRepoObjectQueryData) => {
    fullFileList = [
      ...fullFileList,
      ...getRepoObjectContent(repo.repoObjects as flattenObject[])
    ];
  });

  return fullFileList;
}
