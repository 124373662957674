import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { DataGridPro, GridRowModel } from "@mui/x-data-grid-pro";
import { Button, Form, Modal } from "react-bootstrap";
import { removeTags, shortenString } from "../../helpers/stringHelper";
import { columnsRender, MenuContext } from "../../helpers/tableViewHelper";
import { useTableViewHook } from "../../hooks/tableViewHook";
import { useUserHelper } from "../../hooks/userHelper";
import RenameModal from "../contextMenu/fileOperations/renameModal";
import HideModal from "../contextMenu/fileOperations/hideModal";
import CopyMoveModal from "../contextMenu/fileOperations/copyMoveModal";
import UncompressModal from "../contextMenu/fileOperations/uncompressModal";
import { RepoObject } from "../../backend/types";
import { TableViewListFilesInRepoNavbar } from "../importExportMenu/tableViewListFilesInRepoNavbar";
import { useContext, useState } from "react";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import { Repo, GetRepoObjectQueryData } from "../../backend/types";
import Stack from "@mui/material/Stack";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useAuth } from "../authContextProvider";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import CreateExternalDownloadLink from "../externalLink/createExternalDownloadLink";
import CompressModal from "../contextMenu/fileOperations/compressModal";

const MAX_COMMENT_MENU_LENGTH = 50;

interface TableViewListFilesInRepoProps {
  tree: any[];
  currentFileList: GetRepoObjectQueryData;
  currentSelectedPath: string;
  onNodeSelect: Function;
  setIsCreateFolderWindowOpen: Function;
  isCreateFolderWindowOpen: boolean;
  createFolderMutation: Function;
  files: any;
  refetchRepoObjects: Function;
  tableMenuWasOpenOnPath: string;
  setTableMenuWasOpenOnPath: Function;
  setIsActionOfFilesVisible: Function;
  setIsEditActionVisible: Function;
  setIsTracebilityReportVisible: Function;
  setForceRefresh: Function;
  setSelectedCommentIdFromMenu: Function;
  setIsProcessingExport: Function;
  repo: Repo;
  setIsCopyMoveModalOpenHook: Function;
  setCurrentSelectedRepoId: Function;
  currentSelectedRepoId: string;
  repoObjects: any;
  getRepoObjects: Function;
  refetchRepoObjectsForPath: Function;
  areRepoObjectsLoading: boolean;
}
// Table view component. A list of files, together with all of the logic and functions associated with uploading, downloading, importing and exporting them
export function TableViewListFilesInRepo(props: TableViewListFilesInRepoProps) {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const { treeHierarchyGlobal } = useContext(BrowsePmxActivityContext);
  const [readOnlyCopyAction, setReadOnlyCopyAction] = useState<boolean>(false);

  const {
    selectedRows,
    currentSubArray,
    downloadMultipleFiles,
    handleSelectMultipleRows,
    anchorElFolder,
    handleCloseFolder,
    anchorElFile,
    currentCommentsForSelectedFile,
    handleCloseCommentMenu,
    isFileContextMenuOpen,
    handleCloseFileMenu,
    handleOpenProperties,
    handleOpenTracebilityReport,
    folderName,
    setFolderName,
    createFolder,
    setCurrentCommentsForSelectedFile,
    hideObjects,
    isHideModalOpen,
    closeHideModal,
    isCompressModalOpen,
    setIsCompressModalOpen,
    isUncompressModalOpen,
    setIsUncompressModalOpen,
    hideComment,
    setHideComment,
    openHideModal,
    isRenameModalOpen,
    setIsRenameModalOpen,
    openRenameModal,
    handleClickFile,
    handleClickComment,
    handleClickFolder,
    handleOpenEditAction,
    setIsCopyMoveModalOpen,
    isCopyMoveModalOpen,
    openCopyMoveModal,
    copyOrMoveAction,
    handleOpenExternalDownloadLink,
    isDownloadExternalLinkVisible,
    onCloseExternalDownloadModal,
    setIsDownloadExternalLinkVisible,
    openCompressModal,
    openUncompressModal,
    setSelectedRows,
    errorMutationHide,
    isLoadingMutationHide
  } = useTableViewHook(
    props.currentSelectedPath,
    activityId,
    props.refetchRepoObjects,
    props.tableMenuWasOpenOnPath,
    props.tree,
    props.createFolderMutation,
    props.setTableMenuWasOpenOnPath,
    props.setIsActionOfFilesVisible,
    props.setIsTracebilityReportVisible,
    props.setIsEditActionVisible,
    props.currentFileList,
    props.repo,
    props.repoObjects,
    props.getRepoObjects,
    props.refetchRepoObjectsForPath
  );
  const { isBayerUser } = useUserHelper();
  const { decodedToken } = useAuth();

  return (
    <>
      <TableViewListFilesInRepoNavbar
        tree={props.tree}
        files={props.files}
        currentSelectedPath={props.currentSelectedPath}
        setIsCreateFolderWindowOpen={props.setIsCreateFolderWindowOpen}
        isCreateFolderWindowOpen
        repository={activityData.mainRepository}
        refetchRepoObjects={() => {
          props.refetchRepoObjects();
          props.refetchRepoObjectsForPath();
        }}
        activityId={activityId}
        setForceRefresh={props.setForceRefresh}
        selectedRows={selectedRows}
        currentSubArray={currentSubArray}
        downloadMultipleFiles={downloadMultipleFiles}
        setIsDownloadExternalLinkVisible={setIsDownloadExternalLinkVisible}
        currentFileList={props.currentFileList}
      />
      <MenuContext.Provider
        value={{
          handleClickFolder,
          handleClickFile,
          handleClickComment,
          onNodeSelect: props.onNodeSelect
        }}
      >
        <ErrorBoundary>
          <div className="table-view-list-container">
            <DataGridPro
              loading={props.areRepoObjectsLoading}
              disableVirtualization={
                decodedToken["email"] === "test-user@bayer.com"
              }
              rows={
                currentSubArray
                  ? (currentSubArray as readonly GridRowModel[])
                  : []
              }
              columns={columnsRender(() => {
                props.refetchRepoObjects();
                props.refetchRepoObjectsForPath();
              }, props.repoObjects.length === 0)}
              pageSize={50}
              rowHeight={50}
              disableSelectionOnClick
              checkboxSelection
              className="browser_repository_table"
              selectionModel={selectedRows}
              onSelectionModelChange={handleSelectMultipleRows}
              getRowId={(row) => row.absolutePath}
              classes={
                props.currentFileList.isReadOnlyRepository
                  ? {
                      virtualScroller: "opacity-60"
                    }
                  : {}
              }
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No rows.
                    {(!props.tree || props.tree.length === 0) &&
                      "It is possible that the system is processing new files. If that is the case, please refresh again in a few minutes."}
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Local filter returns no result
                  </Stack>
                )
              }}
            />
          </div>
        </ErrorBoundary>
      </MenuContext.Provider>

      <Menu
        className="context_menu_folder"
        id="simple-menu"
        anchorEl={anchorElFolder}
        open={Boolean(anchorElFolder)}
        onClose={handleCloseFolder}
        PaperProps={{
          style: {
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            marginTop: "15px",
            marginLeft: "5px"
          }
        }}
      >
        {!props.currentFileList.isReadOnlyRepository && (
          <div>
            {props.tableMenuWasOpenOnPath && (
              <MenuItem onClick={openRenameModal} id="rename_menu_context_item">
                Rename
              </MenuItem>
            )}
            <MenuItem
              onClick={() => openCopyMoveModal("move")}
              id="context-menu__move-item-folder"
            >
              Move{selectedRows.length !== 0 && " Selected Folder(s)"}
            </MenuItem>
            <MenuItem onClick={openHideModal}>
              Hide{selectedRows.length !== 0 && " Selected Folder(s)"}
            </MenuItem>
          </div>
        )}
        <MenuItem
          onClick={() => {
            openCopyMoveModal("copy");
            setReadOnlyCopyAction(props.currentFileList.isReadOnlyRepository);
          }}
          id="context-menu__copy-item-folder"
        >
          Copy{selectedRows.length !== 0 && " Selected Folder(s)"}
        </MenuItem>
        <MenuItem onClick={openCompressModal} id="context_menu_compress_folder">
          Compress{selectedRows.length !== 0 && " Selected Folder(s)"}
        </MenuItem>
      </Menu>
      <Menu
        className="context_menu_comment"
        id="simple-menu"
        anchorEl={anchorElFile}
        open={Boolean(
          currentCommentsForSelectedFile &&
            currentCommentsForSelectedFile.length
        )}
        onClose={handleCloseCommentMenu}
        PaperProps={{
          style: {
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            marginTop: "15px",
            marginLeft: "5px"
          }
        }}
      >
        {currentCommentsForSelectedFile &&
          currentCommentsForSelectedFile.map((comment, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                props.setSelectedCommentIdFromMenu(comment.id);
                setCurrentCommentsForSelectedFile([]);
              }}
              className="comment_in_menu_table_view"
            >
              {shortenString(removeTags(comment.text), MAX_COMMENT_MENU_LENGTH)}
              <br />
              <div className="comment_user_details">
                {comment.createdBy} - {comment.created}{" "}
              </div>
            </MenuItem>
          ))}
      </Menu>
      <Menu
        className="context_menu_file"
        id="simple-menu"
        anchorEl={anchorElFile}
        open={isFileContextMenuOpen}
        onClose={handleCloseFileMenu}
        PaperProps={{
          style: {
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            marginTop: "15px",
            marginLeft: "5px"
          }
        }}
      >
        <MenuItem onClick={downloadMultipleFiles}>
          Download{selectedRows.length !== 0 && " Selected File(s)"}
        </MenuItem>
        {selectedRows.length === 0 &&
          props.tableMenuWasOpenOnPath.endsWith(".zip") && (
            <MenuItem onClick={openUncompressModal} id="uncompress_menu_item">
              Uncompress
            </MenuItem>
          )}

        <MenuItem
          disabled={!activityData?.mainRepository?.isStandard}
          onClick={() => handleOpenExternalDownloadLink()}
        >
          Generate External Download Link
          {selectedRows.length !== 0 && " For Selected File(s)"}
        </MenuItem>
        <Divider />

        {!props.currentFileList.isReadOnlyRepository && (
          <div>
            <MenuItem onClick={openHideModal}>
              Hide{selectedRows.length !== 0 && " Selected File(s)"}
            </MenuItem>
            {props.tableMenuWasOpenOnPath && selectedRows.length <= 1 && (
              <MenuItem onClick={openRenameModal} id="context_menu_rename_file">
                Rename
              </MenuItem>
            )}
            <MenuItem
              onClick={() => openCopyMoveModal("move")}
              id="context-menu__move-item-file"
            >
              Move{selectedRows.length !== 0 && " Selected File(s)"}
            </MenuItem>
          </div>
        )}
        <MenuItem
          onClick={() => {
            openCopyMoveModal("copy");
            setReadOnlyCopyAction(props.currentFileList.isReadOnlyRepository);
          }}
          id="context-menu__copy-item-file"
        >
          Copy{selectedRows.length !== 0 && " Selected File(s)"}
        </MenuItem>
        {!props.currentFileList.isReadOnlyRepository && (
          <MenuItem onClick={openCompressModal} id="context_menu_compress_file">
            Compress
            {selectedRows.length !== 0 && " Selected File(s)"}
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleOpenEditAction}>Creating Action</MenuItem>
        {isBayerUser && (
          <MenuItem onClick={handleOpenTracebilityReport}>
            Traceability Report
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenProperties}>Properties</MenuItem>
      </Menu>
      <Modal
        show={props.isCreateFolderWindowOpen}
        onHide={() => props.setIsCreateFolderWindowOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create new Folder in {props.currentSelectedPath}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>
              Name <span className="red_form">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              required
              placeholder="Enter new folder name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value.replace("/", ""))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props.setIsCreateFolderWindowOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!folderName}
            onClick={createFolder}
            id="modalCreateFolderButton"
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
      <HideModal
        isHideModalOpen={isHideModalOpen}
        closeHideModal={closeHideModal}
        selectedRows={selectedRows}
        tableMenuWasOpenOnPath={props.tableMenuWasOpenOnPath}
        hideComment={hideComment}
        setHideComment={setHideComment}
        hideObjects={hideObjects}
        errorMutationHide={errorMutationHide}
        isLoading={isLoadingMutationHide}
      />
      <CompressModal
        isCompressModalOpen={isCompressModalOpen}
        closeCompressModal={() => {
          setIsCompressModalOpen(false);
        }}
        currentSelectedPath={props.currentSelectedPath}
        selectedRows={
          selectedRows.length === 0
            ? [props.tableMenuWasOpenOnPath]
            : selectedRows
        }
        currentSubArray={currentSubArray}
        onFinish={() => {
          props.refetchRepoObjects();
          props.refetchRepoObjectsForPath();
          setSelectedRows([]);
        }}
      />
      <UncompressModal
        isUncompressModalOpen={isUncompressModalOpen}
        closeUncompressModal={() => {
          setIsUncompressModalOpen(false);
        }}
        tableMenuWasOpenOnPath={props.tableMenuWasOpenOnPath}
        versionId={
          props.currentFileList.repoObjects.find(
            (file: RepoObject) => file.name === props.tableMenuWasOpenOnPath
          )?.versionId
        }
        refetchRepoObjects={() => {
          props.refetchRepoObjects();
          props.refetchRepoObjectsForPath();
        }}
      />
      <RenameModal
        activityId={activityId}
        tableMenuWasOpenOnPath={props.tableMenuWasOpenOnPath}
        selectedRows={selectedRows}
        isRenameModalOpen={isRenameModalOpen}
        setIsRenameModalOpen={setIsRenameModalOpen}
        refetchRepoObjects={() => {
          props.refetchRepoObjects();
          props.refetchRepoObjectsForPath();
        }}
        tree={props.currentFileList.repoObjects}
        currentSelectedPath={props.currentSelectedPath}
      />
      <CopyMoveModal
        activityId={activityId}
        readOnlyCopyAction={readOnlyCopyAction}
        selectedRows={
          selectedRows.length > 0
            ? selectedRows
            : [props.tableMenuWasOpenOnPath]
        }
        isCopyMoveModalOpen={isCopyMoveModalOpen}
        setIsCopyMoveModalOpen={setIsCopyMoveModalOpen}
        refetchRepoObjects={() => {
          props.refetchRepoObjects();
          props.refetchRepoObjectsForPath();
        }}
        tree={treeHierarchyGlobal.find((tree) => !tree.isReadOnlyRepository)}
        actionType={copyOrMoveAction}
        currentSelectedPath={props.currentSelectedPath}
        currentSubArray={currentSubArray}
        repoName={
          treeHierarchyGlobal.find((tree) => !tree.isReadOnlyRepository)?.repo
            .name as string
        }
        onNodeSelect={props.onNodeSelect}
        setIsCopyMoveModalOpenHook={props.setIsCopyMoveModalOpenHook}
        setIsCreateFolderWindowOpen={props.setIsCreateFolderWindowOpen}
        setCurrentSelectedRepoId={props.setCurrentSelectedRepoId}
        currentSelectedRepoId={props.currentSelectedRepoId}
        areRepoObjectsLoading={props.areRepoObjectsLoading}
      />
      {isDownloadExternalLinkVisible && (
        <ErrorBoundary>
          <CreateExternalDownloadLink
            activityId={activityId}
            selectedRows={
              selectedRows.length > 0
                ? selectedRows
                : [props.tableMenuWasOpenOnPath]
            }
            currentSelectedPath={props.currentSelectedPath}
            onCloseExternalDownloadModal={onCloseExternalDownloadModal}
            repoId={props.repo.id}
            currentFileList={props.currentFileList}
          />
        </ErrorBoundary>
      )}
    </>
  );
}
