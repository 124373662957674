import { useContext } from "react";
import NavBarPmxActivity from "./navbarPmxActivity";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useGetActionsQuery } from "../../backend/hooks/action/queryGetActions";
import { Link } from "react-router-dom";
import { Spinner, Button } from "react-bootstrap";
import EditAction from "../editAction";
import { ActionFile, ActionFileType, ActionStatus } from "../../backend/types";
import { renderCellExpand } from "../abstractComponents/renderCellExpand";
import { getPreviewURL } from "../../helpers/urlHelper";
import {
  DataGridPro,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams
} from "@mui/x-data-grid-pro";
import {
  ActionSource,
  ActionSourceType,
  ActionType,
  ActionTypeType,
  QualityCheckStatus,
  QualityCheckStatusType,
  getKeyByValue
} from "../../helpers/stringHelper";
import Error from "../abstractComponents/error";
import { customFilters } from "../../helpers/tableViewHelper";
import RefreshIcon from "@mui/icons-material/Refresh";
import { checkIfActionIsNotEditable } from "../../helpers/actionHelper";

export function ActionsTab() {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);

  function renderActionFiles(row: any) {
    return (
      <div key={row.id} className={"action_list_element"}>
        {[...row.actionFiles]
          .sort((af1: ActionFile, af2: ActionFile) => {
            return (
              Object.keys(ActionFileType).indexOf(af1?.actionFileType) -
              Object.keys(ActionFileType).indexOf(af2?.actionFileType)
            );
          })
          .map((af: ActionFile, index: number) => {
            return af ? (
              <div className="no_line_hight" key={`${row.id}-${index}`}>
                <b>
                  <a
                    href={getPreviewURL(
                      String(activityId),
                      af?.file?.versionId!,
                      af?.file?.name!,
                      af?.file?.repo?.id!,
                      true
                    )}
                  >
                    <span className="repo_name_link">
                      {af?.file?.repo?.name}/
                    </span>
                    {af?.file?.name} - ({af?.file?.revision})
                  </a>
                </b>{" "}
                - {getKeyByValue(ActionFileType, af?.actionFileType)}
              </div>
            ) : (
              <></>
            );
          })}
        {row?.hasAdditionalFilesLinked && <div>And More Files...</div>}
      </div>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 80,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <>
          {params.row.id}{" "}
          <EditAction
            actionId={params.row.id}
            notEditableAction={checkIfActionIsNotEditable(
              params.row.actionType
            )}
          />
        </>
      )
    },
    {
      field: "actionType",
      headerName: "Type",
      filterOperators: customFilters,

      width: 120,
      valueGetter: (params: GridValueGetterParams) =>
        ActionType[params.row.actionType as ActionTypeType]
    },
    {
      field: "actionFiles",
      headerName: "Files",
      filterOperators: customFilters,

      width: 600,
      valueGetter: (params: GridValueGetterParams) =>
        Array(params.row?.actionFiles)
          .sort((af1: ActionFile, af2: ActionFile) => {
            return (
              Object.keys(ActionFileType).indexOf(af1?.actionFileType) -
              Object.keys(ActionFileType).indexOf(af2?.actionFileType)
            );
          })
          .map(
            (af: ActionFile) =>
              af?.file &&
              af?.file.repo?.name +
                " - " +
                af?.file.name +
                " - (" +
                af?.file.revision +
                ") - " +
                getKeyByValue(ActionFileType, af?.actionFileType)
          )
          .join("; "),
      renderCell: (params: GridRenderCellParams<any, any, any>) =>
        renderActionFiles(params.row)
    },
    {
      field: "actionQualityCheckStatus",
      headerName: "QC Status",
      filterOperators: customFilters,
      width: 80,
      valueGetter: (params: GridValueGetterParams) =>
        QualityCheckStatus[
          params.row.actionQualityCheckStatus as QualityCheckStatusType
        ]
    },
    {
      field: "startDatetime",
      headerName: "Executed At",
      filterOperators: customFilters,
      width: 120
    },
    {
      field: "userId",
      headerName: "User",
      filterOperators: customFilters,
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.user.email
    },
    {
      field: "description",
      headerName: "Description",
      filterOperators: customFilters,
      width: 200
    },
    {
      field: "action",
      headerName: "Activity",
      filterOperators: customFilters,
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.pmxActivity && params.row.pmxActivity.trialNumber
    },
    {
      field: "Repo",
      headerName: "Repository",
      filterOperators: customFilters,
      width: 160,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.pmxActivity && params.row.pmxActivity.mainRepository.name
    },
    {
      field: "actionSource",
      headerName: "Environment",
      filterOperators: customFilters,
      width: 160,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        ActionSource[params.row.actionSource as ActionSourceType]
    },
    {
      field: "actionStatus",
      headerName: "Status",
      filterOperators: customFilters,
      width: 120,
      valueGetter: (params: GridValueGetterParams) =>
        getKeyByValue(ActionStatus, params.row.actionStatus)
    },
    {
      field: "actionStatusDescription",
      headerName: "Status Description",
      filterOperators: customFilters,
      width: 120,
      renderCell: renderCellExpand
    },
    {
      field: "additionalDetails",
      headerName: "Additional Details/Destination",
      filterOperators: customFilters,
      width: 180,
      hide: true
    },
    {
      field: "sourceFolder",
      headerName: "External Folder",
      filterOperators: customFilters,
      width: 160
    },
    {
      field: "sourceFiles",
      headerName: "External Files",
      filterOperators: customFilters,
      width: 160
    },

    {
      field: "jobId",
      headerName: "Job",
      filterOperators: customFilters,
      renderCell: (params: GridRenderCellParams<any, any, any>) =>
        params.row.jobId ? (
          <Link
            to={`/activity/${params.row.pmxActivity.id}/execution/${params.row.jobId}`}
          >
            {params.row.jobId}
          </Link>
        ) : (
          ""
        )
    },
    {
      field: "historyLabel",
      headerName: "History Label",
      flex: 2,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.historyLabel ? params.row.historyLabel.name : "";
      }
    }
  ];

  const {
    actions,
    isLoading: isLoadingActions,
    error: errorActions,
    refetchActions
  } = useGetActionsQuery({
    activityId: activityId
  });

  return (
    <>
      <NavBarPmxActivity activeIndex={3} />

      <div
        className="app_content pmx_app_content"
        id="tableViewListFilesInRepoNavbar"
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          ,<Typography>Actions</Typography>
        </Breadcrumbs>
        <Button
          onClick={() => {
            refetchActions();
          }}
          title="Clear cache and refresh file list"
          id="refreshItem"
          className="refresh_item float-end"
          variant="outline-secondary"
          size="sm"
        >
          <RefreshIcon fontSize="small" className="table_view_svg" /> Refresh
          Action List
        </Button>

        {isLoadingActions && (
          <div className="center_div">
            <Spinner animation="border" className="spinner_color" />
            <p>Loading...</p>
          </div>
        )}
        <br />
        <hr />

        {actions?.getActions && actions?.getActions?.actions.length > 0 ? (
          <DataGridPro
            className={"styled_data_grid"}
            rows={actions?.getActions?.actions}
            columns={columns}
            getRowHeight={() => "auto"}
            disableSelectionOnClick
            autoHeight
          />
        ) : (
          <div className="no_repositories_available_div">
            No Actions are accessible
          </div>
        )}
      </div>

      {errorActions && <Error error={errorActions} />}
    </>
  );
}
