import { gql, useQuery } from "@apollo/client";
import { RepoObject } from "../../types";

export const GET_HISTORIC_REPO_OBJECTS_QUERY = gql`
  query getHistoricRepoObjects(
    $repoId: ID!
    $historicDatetime: String
    $path: String
    $isTreeView: Boolean
    $actionId: ID
  ) {
    getHistoricRepoObjects(
      input: {
        repoId: $repoId
        historicDatetime: $historicDatetime
        path: $path
        isTreeView: $isTreeView
        actionId: $actionId
      }
    ) {
      repoObjects {
        __typename
        name
        versionId
        isDir
        created
        createdBy
        md5
        validity {
          overallValidityStatus
          systemValidityStatus
          userValidityStatus
          qualityCheckStatus
        }
        size
      }
    }
  }
`;

export function useGetHistoricRepoObjectsPathQuery(
  repoId: Number | null,
  queryParams: object,
  path: String
) {
  const { data, loading, error } = useQuery(GET_HISTORIC_REPO_OBJECTS_QUERY, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      repoId,
      ...queryParams,
      path,
      isTreeView: false
    },
    skip: repoId === null || repoId === 0
  });

  return {
    filesPath: data?.getHistoricRepoObjects?.repoObjects
      ? data?.getHistoricRepoObjects?.repoObjects.map((object: RepoObject) => ({
          ...object,
          absolutePath: object.name,
          name: object.name.replace(path, "").replace("/", "")
        }))
      : null,
    isLoadingPath: loading,
    errorPath: error
  };
}
