import Badge from "@mui/material/Badge";
import EditAction from "../editAction";
import { useState, useEffect } from "react";
import { useCreationalActionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetCreationalActions";
import { Spinner } from "react-bootstrap";
import { QualityCheckStatus } from "../../backend/types";
import { checkIfActionIsNotEditable } from "../../helpers/actionHelper";

// Component used to render circle for a file related to the creating action
const QcActionFlag = (props: {
  children?: JSX.Element;
  versionId: String | null;
  refetch?: Function;
  qualityCheckStatus?: QualityCheckStatus | null;
}) => {
  const [imageNameForUsage, setImageNameForUsage] = useState(
    "Bayer-PHIL-Icon-Circle-Grey_svg.svg"
  );
  const [titleForUsage, setTitleForUsage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [currentAction, setCurrentAction] = useState<any>();

  const {
    getCreationalActions,
    creationalActions,
    isLoadingCreationalActions,
    refetchCreationalActions
  } = useCreationalActionsLazyQuery();

  useEffect(() => {
    props?.qualityCheckStatus &&
      setImageNameFromAction(props?.qualityCheckStatus);
  }, [props.qualityCheckStatus]);

  useEffect(() => {
    if (showPopup) {
      getCreationalActions({
        variables: {
          versionIds: [props.versionId]
        }
      });
    }
  }, [showPopup]);

  const setImageNameFromAction = (currentLoopingAction: QualityCheckStatus) => {
    switch (currentLoopingAction) {
      case QualityCheckStatus.Successful:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Green_svg.svg");
        setTitleForUsage("Creating Action Successful");
        break;
      case QualityCheckStatus.Marked:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Blue_svg.svg");
        setTitleForUsage("Creating Action QC is Marked");
        break;
      case QualityCheckStatus.Failed:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Red_svg.svg");
        setTitleForUsage("Creating Action's QC Failed");
        break;
      case QualityCheckStatus.NotStarted:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Grey_svg.svg");
        setTitleForUsage("Creating Action's QC Not Started");
        break;
      default:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Grey_svg.svg");
        setTitleForUsage("Creating Action's QC Not Started");
    }
  };

  useEffect(() => {
    if (creationalActions && creationalActions.getCreationalActions.actions) {
      const currentLoopingAction =
        creationalActions.getCreationalActions.actions[0];

      if (currentLoopingAction !== null) {
        setCurrentAction(currentLoopingAction);
        setImageNameFromAction(currentLoopingAction?.actionQualityCheckStatus);
      }
    }
  }, [creationalActions]);

  return (
    <>
      <Badge
        overlap="circular"
        className="no_background"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        onClick={() => {
          setShowPopup(true);
        }}
        badgeContent={
          isLoadingCreationalActions ? (
            <Spinner animation="grow" size="sm" variant="secondary" />
          ) : (
            <img
              title={titleForUsage}
              src={"/images/" + imageNameForUsage}
              alt="QC Flag"
              className={
                "qc_badge qc_circle_flag " + imageNameForUsage.split(".")[0]
              }
            />
          )
        }
      >
        {props.children}
      </Badge>
      {currentAction && showPopup && !isLoadingCreationalActions && (
        <EditAction
          actionId={currentAction.id}
          openDirectly={true}
          onClose={() => {
            setShowPopup(false);
          }}
          refetch={() => {
            if (props.refetch) {
              props.refetch();
            }
            refetchCreationalActions();
          }}
          notEditableAction={checkIfActionIsNotEditable(
            currentAction.actionType
          )}
        />
      )}
    </>
  );
};

export default QcActionFlag;
