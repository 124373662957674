import { gql, useQuery } from "@apollo/client";
export const GET_USER_VALIDATION = gql`
  query getUserValidations($versionId: ID!, $pageNumber: Int, $pageSize: Int) {
    getUserValidations(
      input: {
        versionId: $versionId
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      userValidations {
        id
        revision
        validityStatus
        created
        createdBy
        description
      }
    }
  }
`;

export function useGetUserValidations(versionId: String) {
  const {
    data: userValidations,
    loading: isLoadingValidationQuery,
    error: errorValidationQuery,
    refetch: refetchUserValidation
  } = useQuery(GET_USER_VALIDATION, {
    variables: { versionId, pageSize: 1000 },
    notifyOnNetworkStatusChange: true
  });

  return {
    userValidations,
    refetchUserValidation,
    isLoadingValidationQuery,
    errorValidationQuery
  };
}
